<template>
    <b-modal 
    hide-footer
    :visible="isModalNewFolderShow"
    @change="(val) => $emit('update:is-modal-new-folder-show', val)"
    @shown="getData(parentData,countFolder)"
    >
        <template #modal-title>
        New Folder 
        </template>
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
        <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
          <validation-provider
              #default="validationContext"
              name="Title"
              rules="required"
            >
              <b-form-group
                label="Title"
                label-for="title"
              >
                <b-form-input
                  id="title"
                  v-model="Data.title"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Title"
               
                />
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Submit
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="closeModal"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </validation-observer>
       


    </b-modal>
</template>
<script>
import { BButton, BModal, BForm, BFormGroup, BFormInput, BFormInvalidFeedback } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
//validate
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
//api
import store from '@/store'
import { ref } from '@vue/composition-api'
//alerty
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    model: {
        prop: 'isModalNewFolderShow',
        event: 'update:is-modal-new-folder-show',
    },
    props: {
        isModalNewFolderShow: {
          type: Boolean,
          required: true,
        },
        parentData:{
          type: Number,
          required:true
        },
        countFolder:{
          type:Number
        }
     
      
    },
 components: {
    BButton,
    BModal,
    BForm, BFormGroup, BFormInput, BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  setup(props, { emit }){
    const toast = useToast()
    const userStorage = JSON.parse(localStorage.getItem('userData'));
    const blankData = {
      
      title: '',
      slug: '',
      parent: 0,
      created_by:userStorage.id,
      ordering_count:0
    
   
    }
    const Data = ref(JSON.parse(JSON.stringify(blankData)))
    const getData = (parent,ordering_count)=>{
      Data.value.title = '';
      Data.value.parent = parent;
      Data.value.ordering_count = ordering_count;
       
    }
    const onSubmit = async() => {
         let title =  Data.value.title.replace(/[`~%^&*!@#$()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
          let slug = title.toLowerCase().replace(/\s+/g, '-');
          Data.value.slug = slug;
          let res = await store.dispatch('files-folder/addData',Data.value);
          if(res.data.success){
            toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                title: `Success`,
                icon: 'BellIcon',
                variant: 'success',
                text: `${res.data.message}`,
                },
            });

          }else{
            toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                title: `Success`,
                icon: 'BellIcon',
                variant: 'danger',
                text: `${res.data.message}`,
                },
            });

          }
        //  console.log('res',res);
          //updateData
        emit('refetch-data')
        emit('update:is-modal-new-folder-show', false)
    }
    const resetData = () => {
        //Data.value = JSON.parse(JSON.stringify(blankData))
    }
    const closeModal = ()=>{
        emit('update:is-modal-new-folder-show', false)
    }
    const {
        refFormObserver,
        getValidationState,
        resetForm,
      } = formValidation(resetData)
    return {
        refFormObserver,
        getValidationState,
        resetForm,
        getData,
        onSubmit,
        resetData,
        closeModal,
        Data
    }

  }
    
}
</script>