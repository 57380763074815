<template>
<b-card   header-tag="header" >
    <template #header>
        <div class="w-100 d-flex justify-content-between">
            
            <b-breadcrumb class="breadcrumb-slash "  style="width: 50%;" v-if="breadcrumbsData.length > 0">
              <template v-for="(bred,index) in breadcrumbsData" >
                <b-breadcrumb-item v-if="(index+1) == breadcrumbsData.length"  :key="'b-'+index" active>
                  
                  {{  bred.title }}
                </b-breadcrumb-item>
                <b-breadcrumb-item v-else  :key="'b-'+index" >
                 <b-link  @click="clickBread(bred,index)"> {{  bred.title }}</b-link>
                </b-breadcrumb-item>
              </template>
          
          
        </b-breadcrumb>
            <div>
              <b-button 
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-success mr-2"
                @click="newFolder"
              >
                New Folder
              </b-button>
              <b-button 
                v-if="parent > 0"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="uploadFiles"
              >
                Upload File
              </b-button>
            </div>
        </div>
        
       
        <hr />
      </template>
      

    <draggable
        v-model="folders"
        tag="ul"
        class="list-group d-flex flex-wrap   list-group-horizontal cursor-move"
        @change="changeOrderFolder"
       
      
    >
      <template
        v-for="(item, index) in folders"
      >
        <b-list-group-item 
          v-if="!item.types"
          :key="'folder-'+index"
          tag="li"
          class="border-0 text-center p-2"
          @contextmenu.prevent="$refs.folder.open($event,item)"
        
        >
        <div  
        
        @dblclick="openFolder(item)">
        <feather-icon
              icon="FolderIcon"
              size="62"
            />
          <div class="text-center">
              <strong> {{ item.title }}</strong>
          </div>

        </div>
        
        
          
        </b-list-group-item>
        <b-list-group-item 
          v-else
          :key="'file-'+index"
          tag="li"
          class="border-0 text-center p-2"
          @contextmenu.prevent="$refs.files.open($event,item)"
        
        >
       
        <div>
        <b-img
            :src="item.fullpath"
            v-if="item.types == 'i'"
            style="height: 62px;"
            thumbnail
            
          />
        
            <feather-icon
              icon="FileIcon"
              size="62"
              v-else
            />
          <div class="text-center">
              <strong> {{ item.title }}</strong>
          </div>

        </div>
        
          
        </b-list-group-item>
      </template>
      
      
        

     
     
    </draggable>
   
    <vue-context ref="folder" @open="onOpen">
      
      <li>
        <b-link
          v-for="data in menuFolder"
          :key="data.text"
          class="d-flex align-items-center"
          @click="onOptionClick(data,itemData)"
        >
          <feather-icon
            :icon="data.icon"
            size="16"
          />
          <span class="ml-75">{{ data.text }}</span>
        </b-link>
      </li>
    </vue-context>
    <vue-context ref="files"  @open="onOpen"  >
      <li>
        <b-link
          v-for="data in menuFiles"
          :key="data.text"
          class="d-flex align-items-center"
          @click="optionFileClicked(data,itemData)"
        >
          <feather-icon
            :icon="data.icon"
            size="16"
          />
          <span class="ml-75">{{ data.text }}</span>
        </b-link>
      </li>
    </vue-context>
    <rename-modal :is-modal-rename-show.sync="isModalRenameShow" :itemData.sync="itemData" @refetch-data="getAll" />
    <new-folder-modal :is-modal-new-folder-show.sync="isModalNewFolderShow" :parentData.sync="parent" :countFolder.sync="folders.length" @refetch-data="getAll" />
    <upload-files-modal :is-modal-upload-files-show.sync="isModalUploadFilesShow" :parentData.sync="parent" :countFolder.sync="folders.length" @refetch-data="getAll" />
    <files-detail-modal :is-modal-detail-files-show.sync="isModalDetailFilesShow" :itemData.sync="itemData" :parentData.sync="parent" :countFolder.sync="folders.length" @refetch-data="getAll" />
</b-card>
<!--isModalNewFolderShow-->
    
</template>

<script>
import { ref,onUnmounted } from '@vue/composition-api'
import draggable from 'vuedraggable'

import Prism from 'vue-prism-component'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import Ripple from 'vue-ripple-directive'
 import {
    BCard,BCardHeader, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
    BListGroup,BListGroupItem,BBreadcrumb, BBreadcrumbItem
    
} from 'bootstrap-vue'

import VueContext from 'vue-context'

import useFilesList from './useFilesList'
import RenameModal from './RenameModal.vue'
import NewFolderModal from './NewFolderModal.vue'
import UploadFilesModal from './UploadFilesModal.vue'
import FilesDetailModal from './FilesDetailModal.vue'

//api
import axios from '@axios'
import store from '@/store'

import folderStoreModule from '../folderStoreModule'

import filesStoreModule from '../filesStoreModule'

//downloadzip
import { saveAs } from 'save-as'
import JSZip from 'jszip'


import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { base64StringToBlob } from 'blob-util';


export default {
    components:{
        BCard,BCardHeader, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,
        BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
        draggable,Prism,VueContext,
        BListGroup,BListGroupItem,
        RenameModal,
        NewFolderModal,
        UploadFilesModal,
        BBreadcrumb,
        BBreadcrumbItem,
        FilesDetailModal
        
    },
    directives:{
        'b-modal': VBModal,
        Ripple

    },
    setup(){
      const FOLDER_STORE_MODULE_NAME = 'files-folder'
      if (!store.hasModule(FOLDER_STORE_MODULE_NAME)) store.registerModule(FOLDER_STORE_MODULE_NAME, folderStoreModule)
      onUnmounted(() => {
        if (store.hasModule(FOLDER_STORE_MODULE_NAME)) store.unregisterModule(FOLDER_STORE_MODULE_NAME)
      });
      const FILES_STORE_MODULE_NAME = 'app-files'
      if (!store.hasModule(FILES_STORE_MODULE_NAME)) store.registerModule(FILES_STORE_MODULE_NAME, filesStoreModule)
      onUnmounted(() => {
        if (store.hasModule(FILES_STORE_MODULE_NAME)) store.unregisterModule(FILES_STORE_MODULE_NAME)
      });
      
        const {
            folders,
            fileslist,
            changeOrderFolder,
            openFolder,
            menuFolder,
            optionClicked,
            isModalRenameShow,
            isModalNewFolderShow,
            isModalUploadFilesShow,
            isModalDetailFilesShow,

            getAll,
            itemData,
            breadcrumbsData,
            clickBread,
            parent,
            newFolder,
            deleteData,
            deleteFilesData,
            uploadFiles,
            menuFiles
           // handleClickRight
        } = useFilesList();

        return {
            folders,
            fileslist,
            changeOrderFolder,
            openFolder,
            menuFolder,
            optionClicked,
            isModalRenameShow,
            isModalNewFolderShow,
            isModalUploadFilesShow,
            isModalDetailFilesShow,
            getAll,
            itemData,
            breadcrumbsData,
            clickBread,
            parent,
            newFolder,
            deleteData,
            deleteFilesData,
            uploadFiles,
            menuFiles
            //handleClickRight
        }
        //const lists = ref();
    },
    data() {
        return {
        
          
         
        }
    },
    methods:{
       async forceFileDownload(item){ 
         let itm = {};
         itm.filename= item.filename;
         itm.folder = 'storage'
         var data = JSON.stringify(itm);
        //let res1 = await axios.get(item.fullpath, { responseType: 'blob' });
        let res = await axios.post('/copy/v1/download-s3',data);
        const blob = base64StringToBlob(res.data.base64String, res.data.ContentType);
        console.log('blob',blob);
      
       
       
        
       // var fileURL = window.URL.createObjectURL(new Blob([res.data]));
        var FILE = window.URL.createObjectURL(blob);
        var docUrl = document.createElement('a');
        docUrl.href = FILE;
        docUrl.setAttribute('download', item.filename);
        document.body.appendChild(docUrl);
        docUrl.click();
       
        },
        async forceFileZipDownload(item) {
          let filter = {
            parent:item.id,
            order_by:'ordering_count ASC'
        }
          const frds = await store.dispatch('files-folder/fetchData',filter);
          let files = frds.data.data;
          var zip = new JSZip();
          var img = zip.folder(item.title);
          if(files.length > 0){
            let downloads = await Promise.all( files.filter((val)=>{
                if(val.fullpath){
                  return val;

                }
            })
            .map(async function(obj) {
             const reader = new FileReader()
             let itm = {};
         itm.filename= obj.filename;
         itm.folder = 'storage'
         var data = JSON.stringify(itm);
               // let res = await    axios.get(obj.fullpath, { responseType: 'arraybuffer' });
            //   let res2 = await axios.get('https://findbangkokroom.com/public/img/place/L2CsER1Af9_file_20160426232153.jpg',{responseType:'arraybuffer'});
              // console.log('res-pst',res2);
                let res = await axios.post('/copy/v1/download-s3',data);
                 const blob = base64StringToBlob(res.data.base64String, res.data.ContentType);
             
            //  console.log('res-p',res);
              return  await img.file(obj.filename,blob)
            
           
            }));
           // console.log('frds', downloads);
            zip.generateAsync({
              type: "blob"
            }).then(function(content) {
              saveAs(content, item.title+".zip")
            })
          
          
        
          }
        
        },
        onOpen(event,data) {
  
          this.itemData = data;
              //  console.log('The context menu was opened',data);
      
        },
        optionFileClicked(data,item){
          
          if(data.text == 'Delete'){
            this.$bvModal
              .msgBoxConfirm('Please confirm that you want to delete File Name '+item.name, {
              title: 'Please Confirm',
              size: 'sm',
              okVariant: 'danger',
              okTitle: 'Yes',
              cancelTitle: 'No',
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true,
              })
              .then(value => {
                  if(value === true){
                     this.deleteFilesData(item.id)
                  }
              
              //this.deleteData(id)
              })
          }else if(data.text == 'Download'){
            this.forceFileDownload(item);
          }else{
            this.optionClicked(data,item);
          }

        },
        onOptionClick(data,item){
          if(data.text == 'Delete'){
              this.$bvModal
              .msgBoxConfirm('Please confirm that you want to delete Folder Name '+item.title, {
              title: 'Please Confirm',
              size: 'sm',
              okVariant: 'danger',
              okTitle: 'Yes',
              cancelTitle: 'No',
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true,
              })
              .then(value => {
                  if(value === true){
                    this.deleteData(item.id)
                  }
              
              //this.deleteData(id)
              });
          }else if(data.text == 'Download'){
            console.log('zip',item);
            this.forceFileZipDownload(item);


          }else{
            this.optionClicked(data,item);
          }
          
           // this.$refs.menu.open
        }
    }
    
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-context.scss';
.list-group-horizontal {
    flex-direction: row;
    li{
      width: 150px;
    }
}

</style>