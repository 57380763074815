import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

import VueContext from 'vue-context'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useFilesList(){
    const toast = useToast();
    const isModalRenameShow = ref(false);
    const isModalNewFolderShow = ref(false);
    const isModalUploadFilesShow = ref(false);
    const isModalDetailFilesShow = ref(false);
    const itemData = ref({});
    const parent = ref(0);
    const itemsAll = ref([]);
    const resData = ref({});
    //const folders = ref([]);
    
   const folders = ref([])
   const fileslist = ref([])
    const menuf =  [
        { icon: 'FileIcon', text: 'Open' },
        { icon: 'PlusIcon', text: 'Rename' },
        { icon: 'DownloadIcon', text: 'Download' },
       // { icon: 'UploadIcon', text: 'Upload' },
        { icon: 'XIcon', text: 'Delete' },
     
   
    ]

    const menuFolder = ref(JSON.parse(JSON.stringify(menuf)));

    const menufi =  [
        
       // { icon: 'PlusIcon', text: 'Rename' },
        { icon: 'FileTextIcon', text: 'Detail' },
        { icon: 'DownloadIcon', text: 'Download' },
   
        { icon: 'XIcon', text: 'Delete' },
        
    ]

    const menuFiles = ref(JSON.parse(JSON.stringify(menufi)));
    
    const breads =  [
        { id: 0, title: 'Storage' },
       
   
    ]
    const breadcrumbsData = ref(JSON.parse(JSON.stringify(breads)));

    const getAll = async()=>{
        folders.value = [];
        let filter = {
            parent:parent.value,
            order_by:'ordering_count ASC'
        }
        const frds = await store.dispatch('files-folder/fetchData',filter);
        if(frds.data.success){
            folders.value = frds.data.data;
            //console.log('folder-result',frds.data.data);
        }
       /* if(parent.value > 0){
            let filter = {
                folder_id:parent.value,
                order_by:'ordering_count ASC'
            }
            const fls = await store.dispatch('app-files/fetchData',filter);
           

        }*/
       



        

    }
    getAll();
    const changeOrderFolder = async()=>{
       //console.log('lists',folders.value);
       const rest =  await store.dispatch('files-folder/updateOrdering',folders.value);
       
       //console.log('lists',rest.data);

    }
    const openFolder = async(item)=>{
        parent.value = item.id;
        breadcrumbsData.value.push(item)
        getAll();
        
        console.log('openFolder');
     

    }
    const newFolder = ()=>{
        isModalNewFolderShow.value = true;
    }   
    const optionClicked = (data,item)=>{
        //console.log('itemData',itemData);
       
        if(data.text == 'Rename'){
            isModalRenameShow.value = true;

        }
        if(data.text == 'Detail'){
            isModalDetailFilesShow.value = true;

        }
       
        if(data.text == 'Open'){
            parent.value = item.id;
            breadcrumbsData.value.push(item)
            getAll();

        }

    }
    const clickBread = (bread,index)=>{
        let count = breadcrumbsData.value.length;
        console.log('bread',bread);
        //count = index;
       // console.log(count,breadcrumbsData.value.length);
        //bread.id
        //console.log('count',count);
        breadcrumbsData.value.splice(index+1)
       
        parent.value = bread.id;
        getAll();
        


    }
    const deleteData = async(id)=>{
      const res =  await store.dispatch('files-folder/deleteData',id);
      console.log('res',res);
      toast({
        component: ToastificationContent,
        props: {
          title: "Delete Floder Success",
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      })
      getAll();

    }
    const deleteFilesData = async(id)=>{
        const res =  await store.dispatch('app-files/deleteData',id);
        console.log('res',res);
        toast({
          component: ToastificationContent,
          props: {
            title: "Delete Files Success",
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
        getAll();
  
      }
    const uploadFiles = async ()=>{
        isModalUploadFilesShow.value = true;
    }
    return {

        folders,
        fileslist,
        changeOrderFolder,
        openFolder,
        menuFolder,
        optionClicked,
        isModalRenameShow,
        isModalNewFolderShow,
        isModalUploadFilesShow,
        getAll,
        itemData,
        breadcrumbsData,
        clickBread,
        parent,
        newFolder,
        deleteData,
        deleteFilesData,
        uploadFiles,
        menuFiles,
        isModalDetailFilesShow
        //handleClickRight

    }

}