<template>
    <b-modal 
    hide-footer
    :visible="isModalUploadFilesShow"
    @change="(val) => $emit('update:is-modal-upload-files-show', val)"
    @shown="getData(parentData,countFolder)"
    >
        <template #modal-title>
        Upload File
        </template>
      <div class="flex w-full h-screen items-center justify-center text-center" >
        <div class="dropbox-poopup"  @drop="drop">
          <b-form-file
            multiple
                v-model="Data.images"
                ref="refPreviewEl"
                class="input-file"
                accept=".jpg, .png, .gif, .webp, .pdf, .txt, .zip, .xls"
                placeholder="Choose file"
                @input="onChange($event)"
        
            />
            <p >
              Drag your file(s) here to begin<br> or click to browse
          </p>
     
        </div>
        <b-list-group v-if="files.length > 0">
          <b-list-group-item   v-for="(fl,index) in files" :key="'file-img-'+index" class="d-flex justify-content-between align-items-center">
            <img :src="fl.src"  v-if="fl.type == 'image'" style="width:50px; height: 50px; object-fit: cover;" />
            <feather-icon icon="FileTextIcon" size="50" v-else />
            <div class="w-100">
              <b-progress
              :value="fl.progress"
              max="100"
              class="ml-1 progress-bar-success "
              striped
            />
            {{ fl.name }}
            </div>
            
        
        </b-list-group-item>

        </b-list-group>
       
      </div>


    </b-modal>
</template>
<script>
import { BButton, BModal, BForm, BFormGroup,BFormFile, BFormInput, BFormInvalidFeedback, BProgress, BListGroup, BListGroupItem, BBadge } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
//validate
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
//api
import axios from '@axios'
import store from '@/store'
import { ref } from '@vue/composition-api'
//alerty
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { $themeConfig } from '@themeConfig'
//upload
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
export default {
    model: {
        prop: 'isModalUploadFilesShow',
        event: 'update:is-modal-upload-files-show',
    },
    props: {
        isModalUploadFilesShow: {
          type: Boolean,
          required: true,
        },
        parentData:{
          type: Number,
          required:true
        },
        countFolder:{
          type:Number
        }
     
      
    },
 components: {
    BButton,
    BModal,
    BForm, BFormGroup, BFormInput, BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    BProgress,
    BListGroup, BListGroupItem,
    BBadge
  },
  directives: {
    Ripple,
  },
  setup(props, { emit }){
    const {  appRef } = $themeConfig.app
  // console.log('theme-app',$themeConfig.app);
    const toast = useToast()
    const userStorage = JSON.parse(localStorage.getItem('userData'));
    const filelist = ref([]);
    const refPreviewEl = ref(null)
    const files = ref([]);
    const blankData = {
      
     images:[]
    
   
    }
    const Data = ref(JSON.parse(JSON.stringify(blankData)))
    const getData = (parent,ordering_count)=>{
      files.value = [];
      Data.value.title = '';
      Data.value.parent = parent;
      Data.value.ordering_count = ordering_count;
       
    }
    const onSubmit = async() => {
         let title =  Data.value.title.replace(/[`~%^&*!@#$()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
          let slug = title.toLowerCase().replace(/\s+/g, '-');
          Data.value.slug = slug;
          let res = await store.dispatch('files-folder/addData',Data.value);
          if(res.data.success){
            toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                title: `Success`,
                icon: 'BellIcon',
                variant: 'success',
                text: `${res.data.message}`,
                },
            });

          }else{
            toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                title: `Success`,
                icon: 'BellIcon',
                variant: 'danger',
                text: `${res.data.message}`,
                },
            });

          }
        //  console.log('res',res);
          //updateData
        emit('refetch-data')
        emit('update:is-modal-upload-files-show', false)
    }
    const resetData = () => {
        //Data.value = JSON.parse(JSON.stringify(blankData))
    }
    const closeModal = ()=>{
        emit('update:is-modal-upload-files-show', false)
    }
    const drop = (event)=>{
      console.log('event');

    }
    const onChange = async(event)=>{
      const userStorage = JSON.parse(localStorage.getItem('userData'));
     // console.log('refPreviewEl-event',Data.value.images);
      console.log('refPreviewEl-event',[...refPreviewEl.value.files]);
      let images = Data.value.images;
     
     let flies_a =  images.map((ele,index)=>{
        const  file = ele;
        let types = ele.type.split('/')
        
        let img  = {
                  src:'',
                  loading:true,
                  index:index,
                  type:types[0],
                  name:ele.name,
                  filename:ele.name,
                  mimetype:ele.type,
                  extension:'.'+types[1],
                  filesize:ele.size,
                  ordering_count:index,
                  progress:0,
                  err:false,
                  created_by:userStorage.id,
                  ele:ele
              }
        console.log('img',img);
          if(img.extension == '.png' || img.extension == '.jpg' || img.extension == '.gif' || img.extension == '.webp' || img.extension == '.jpeg'){
            img.types = 'i';

          }else{
            img.types = 'o';
          }
          files.value.push(img);
              
        if(types[0] == 'image'){
          const reader = new FileReader();
          
          reader.addEventListener("load", () => {
              let base64 =  reader.result;
              files.value[index].src = base64;
               
           
            
          }, false);
          reader.readAsDataURL(file)

        }
        return img;
        //console.log('ele',ele.type);

      });
      const count = flies_a.length;
     //console.log('test',test);
     flies_a.map(async(value,index)=>{
      console.log('value',value);
     // console.log('files',index);
      console.log('files', files.value[index]);
      files.value[index].progress = 25;
      let formData = new FormData();
      formData.append('folder_id',Data.value.parent)
      formData.append('images', value.ele);
      formData.append('path', '/var/www/html/uploadfiles/uploads/'+appRef+'/storage/');
      formData.append('savepath', 'uploads/'+appRef+'/storage/');
      formData.append('ordering_count', value.ordering_count);
      formData.append('mimetype', value.mimetype);
      formData.append('name', value.name);
      formData.append('filename', value.filename);
      formData.append('filesize', value.filesize);
      formData.append('extension', value.extension);
      formData.append('created_by', value.created_by);
      formData.append('types', value.types);

      
      //filename
      //filesize
      //extension
      //created_by
      //created_at
      
       let res = await axios.post('files/v1/upload',formData,{
          onUploadProgress: (progressEvent) => {
            files.value[index].progress = 50;
            console.log('progressEvent',progressEvent);
          }
         });
         
      if(res.data.success){
        files.value[index].progress = 100;
        emit('refetch-data')
        if(count == (index+1)){
          emit('update:is-modal-upload-files-show',false);
        }

      }
     });
      
     //var parsedobj = JSON.parse(JSON.stringify(posts[0]))
     // console.log('news',tetm);
     // const uploads = await new Promise(files);
    // console.log('posts',parsedobj);
     /*for(const [index, value] of Object.entries(posts[0])) {
      //files.value[index].progress = 25
      console.log('test',index);
     }
     posts[0].map(async(fl,index)=>{
        
        //files.value[index].progress = 25
        //console.log('test',fl);
       // let res = await axios.put('groups/v1/updateroles/'+this.group.id,data);
        

      });*/
      
      /*let images = Data.value.images;
      images.map((ele,index)=>{
        const  file = ele;
        const reader = new FileReader();
        reader.addEventListener("load", () => {
              let base64 =  reader.result;
              let img  = {
                  src:base64,
                  loading:true,
                  index:index,
                  err:false,
              }
              files.value.push(img);
            
          }, false);
          reader.readAsDataURL(file)
      });*/
    
    }
    const {
        refFormObserver,
        getValidationState,
        resetForm,
      } = formValidation(resetData)
    return {
        refFormObserver,
        getValidationState,
        resetForm,
        getData,
        onSubmit,
        resetData,
        closeModal,
        Data,
        drop,
        filelist,
        refPreviewEl,
        onChange,
        files
       
    }

  },
  methods:{
    /*onChange(){
      this.filelist = [...this.$refs.file.files];
      console.log('file',this.filelist);
    }*/

  }
  
    
}
</script>