import axios from '@axios'
export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/files/v1/folder/getall',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        addData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/files/v1/folder/add',data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        updateData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .put('/files/v1/folder/update/'+queryParams.id,data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        updateOrdering(ctx,queryParams){
            var data = JSON.stringify(queryParams);
            return new Promise((resolve, reject) => {
                axios
                  .post('/files/v1/folder/updateOrdering',data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        deleteData(ctx,id){
          
            
            return new Promise((resolve, reject) => {
              axios
                .delete('/files/v1/folder/delete/'+id)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })

        },
    }
}